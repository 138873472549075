import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = [ "users" ]

  connect () {
    console.log("Users controller connected", this.element)
  }
  
  // Inclui um novo email de usuário e ordena a lista
  includeUserEmail(email) { 
    var assignedTo = this.usersTarget.value 
    var usersArray = assignedTo.length > 0 ? assignedTo.split(", ") : []
    usersArray.push(email)
    this.usersTarget.value = usersArray.sort().join(', ')
  }

  removeUserEmail(email) {
    // exclui o email e ajusta as vírgulas entre palavras
    // this.usersTarget.value = this.usersTarget.value.replace(email, '').replace(' ,' , '')
    var usersArray = this.usersTarget.value.split(", ")
    const index = usersArray.indexOf(email)
    if (index >= 0) {
      usersArray.splice(index, 1)
      this.usersTarget.value = usersArray.join(', ')
    }
  }

  // Seleciona ou não algum usuário para inclusão em objetos específicos do sistema.
  toggleUser() {
    const element = event.target
    if (element.checked) {
      this.includeUserEmail(element.dataset["email"])
    }
    else {
      this.removeUserEmail(element.dataset["email"])
    }
  }

}