import ApplicationController from './application_controller'

/* This is the custom StimulusReflex controller for the NonConformanceRpts Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  /*
   * Regular Stimulus lifecycle methods
   * Learn more at: https://stimulusjs.org/reference/lifecycle-callbacks
   *
   * If you intend to use this controller as a regular stimulus controller as well,
   * make sure any Stimulus lifecycle methods overridden in ApplicationController call super.
   *
   * Important:
   * By default, StimulusReflex overrides the -connect- method so make sure you
   * call super if you intend to do anything else when this controller connects.
  */

  static targets = [ 'caComments', 'notifyList' ]

  connect () {
    super.connect()
    // add your code here, if applicable
  }

  /* Reflex specific lifecycle methods.
   *
   * For every method defined in your Reflex class, a matching set of lifecycle methods become available
   * in this javascript controller. These are optional, so feel free to delete these stubs if you don't
   * need them.
   *
   * Important:
   * Make sure to add data-controller="non-conformance-rpts" to your markup alongside
   * data-reflex="NonConformanceRpts#dance" for the lifecycle methods to fire properly.
   *
   * Example:
   *
   *   <a href="#" data-reflex="click->NonConformanceRpts#dance" data-controller="non-conformance-rpts">Dance!</a>
   *
   * Arguments:
   *
   *   element - the element that triggered the reflex
   *             may be different than the Stimulus controller's this.element
   *
   *   reflex - the name of the reflex e.g. "NonConformanceRpts#dance"
   *
   *   error/noop - the error message (for reflexError), otherwise null
   *
   *   reflexId - a UUID4 or developer-provided unique identifier for each Reflex
   */

  // Assuming you create a "NonConformanceRpts#dance" action in your Reflex class
  // you'll be able to use the following lifecycle methods:

  // beforeDance(element, reflex, noop, reflexId) {
  //  element.innerText = 'Putting dance shoes on...'
  // }

  // danceSuccess(element, reflex, noop, reflexId) {
  //   element.innerText = '\nDanced like no one was watching! Was someone watching?'
  // }

  // danceError(element, reflex, error, reflexId) {
  //   console.error('danceError', error);
  //   element.innerText = "\nCouldn\'t dance!"
  // }

  // afterDance(element, reflex, noop, reflexId) {
  //   element.innerText = '\nWhatever that was, it\'s over now.'
  // }

  // finalizeDance(element, reflex, noop, reflexId) {
  //   element.innerText = '\nNow, the cleanup can begin!'
  // }

  // Ação corretiva concluída com sucesso.
  caSuccess() {
    this.stimulate("NonConformanceRptsReflex#corrective_action_success", event.target.dataset["id"], 
      this.caCommentsTarget.value).then(() => this.clearComments())
  }

  // Ação corretiva não foi bem sucedida.
  caFailure() {
    this.stimulate("NonConformanceRptsReflex#corrective_action_failure", event.target.dataset["id"], 
      this.caCommentsTarget.value).then(() => this.clearComments())
  }

  closeCorrectiveAction() {
    this.stimulate("NonConformanceRptsReflex#close_corrective_action", event.target.dataset["id"])
  }
  
  clearComments() {
    this.caCommentsTarget.value = ""
  }

  // Exclui uma ação corretiva
  destroyCA() {
    const id = event.target.dataset["id"]
    const description = event.target.dataset["description"]
    const ok = confirm(`Confirma a exclusão da ação corretiva ${id} - ${description}?`) 
    if (ok) this.stimulate("NonConformanceRptsReflex#destroy_corrective_action", id)
  }

  destroyTask() {
    const id = event.target.dataset["id"]
    const description = event.target.dataset["description"]
    const ok = confirm(`Confirma a exclusão da tarefa ${id} - ${description}?`)
    if (ok) this.stimulate("NonConformanceRptsReflex#destroy_task", id)
  }

  toggleUserSelection() {
    var email = event.target.dataset.email
    var notifyList = this.notifyListTarget 
    if (event.target.checked) {
      if(!notifyList.value.includes(email)) {
        if (notifyList.value.length > 0) notifyList.value += ", "  
        notifyList.value += email
      }
    } else {
      notifyList.value = notifyList.value.replace(email,'')
      notifyList.value = notifyList.value.replace(" , ", "")
      notifyList.value = notifyList.value.replace(", ,", ",")
    }  
  }

}
